<template>
  <div class="weatherDisasterWarning">
    <div class="weatherHeader">
      <img src="../../../assets/visualization/securityManagement/zklogo.png" alt="" />
      <div class="fullScreen">
        <i class="icon-quanping" v-show="!fullScreen" @click="fullScreenClick" style="cursor: pointer"></i>
        <i class="icon-tuichuquanping" v-show="fullScreen" @click="outFullScreenClick" style="cursor: pointer"></i>
      </div>
    </div>
    <div class="weatherBody">
      <div class="itemBody1">
        <div class="itemBodyTitle">基本信息</div>
        <div class="itemBodyContent">
          <div class="iconBox">
            <div class="iconImg"></div>
            <span style="margin-bottom: 0.26vw">
              <span style="color: #21edff; font-size: 1.406vw">
                {{ infoData.currentMonitoring }}
              </span>
              <span style="font-size: 0.833vw"> 个</span>
            </span>
            <span>当前监测项目</span>
          </div>
          <div class="iconBox">
            <div class="iconImg"></div>
            <span style="margin-bottom: 0.26vw">
              <span style="color: #f32424; font-size: 1.406vw">
                {{ infoData.currentWarning }}
              </span>
              <span style="font-size: 0.833vw"> 个</span>
            </span>
            <span>当前预警项目</span>
          </div>
          <div class="iconBox">
            <div class="iconImg"></div>
            <span style="margin-bottom: 0.26vw">
              <span style="color: #21beff; font-size: 1.406vw">
                {{ infoData.accumulatedMonitoring }}
              </span>
              <span style="font-size: 0.833vw"> 个</span>
            </span>
            <span>累计监测项目</span>
          </div>
          <div class="iconBox">
            <div class="iconImg"></div>
            <span style="margin-bottom: 0.26vw">
              <span style="color: #fedc22; font-size: 1.406vw">
                {{ infoData.accumulatedWarning }}</span>
              <span style="font-size: 0.833vw"> 个</span>
            </span>
            <span>累计预警提示</span>
          </div>
        </div>
      </div>
      <div class="itemBody2">
        <div class="itemBodyTitle warningProject">
          <div>预警项目 今日气象 ({{ warningSum }})</div>
          <div class="buttonBox" @click="warningMore">更多</div>
        </div>
        <div class="itemBodyContent warningProject">
          <div class="tableBox table1" @mouseenter="table1Moving = true"
            @mouseleave="(table1Moving = false), moveData1('#table1', 3000, 3.5)">
            <div class="tableHeader">
              <div class="tableTh">
                <div class="tableTd">序号</div>
                <div class="tableTd">单位名称</div>
                <div class="tableTd">项目名称</div>
                <div class="tableTd">预警事项</div>
                <div class="tableTd">预测时间</div>
                <div class="tableTd">操作</div>
              </div>
            </div>
            <div class="tableBody" id="table1" v-if="table1Data.length > 0">
              <div class="tableTh" v-for="(item, index) in table1Data" :key="index">
                <div class="tableTd">{{ item.sort }}</div>
                <div class="tableTd">{{ item.contract_name }}</div>
                <div class="tableTd">{{ item.prj_name }}</div>
                <div class="tableTd">{{ item.type_name }}</div>
                <div class="tableTd">{{ item.pub_time }}</div>
                <div class="tableTd">
                  <el-button type="text" @click="viewDetails(item)">查看</el-button>
                </div>
              </div>
            </div>
            <div v-else class="tableBody noData"></div>
          </div>
        </div>
      </div>
      <div class="itemBody3">
        <div class="itemBodyTitle warningProject">
          <div>预警处置措施</div>
          <div class="buttonBox" @click="warningMeasure">更多</div>
        </div>
        <div class="itemBodyContent">
          <div class="tableBox table2" @mouseenter="table2Moving = true" @mouseleave="
            (table2Moving = false), moveData2('#table2', 3000, 3.5)
            ">
            <div class="tableHeader">
              <div class="tableTh">
                <div class="tableTd">序号</div>
                <div class="tableTd">单位名称</div>
                <div class="tableTd">项目名称</div>
                <div class="tableTd">标题</div>
                <div class="tableTd">发布时间</div>
                <div class="tableTd">操作</div>
              </div>
            </div>
            <div class="tableBody" id="table2" v-if="table2Data.length > 0">
              <div class="tableTh" v-for="(item, index) in table2Data" :key="index">
                <div class="tableTd">{{ item.sort }}</div>
                <div class="tableTd">{{ item.contractName }}</div>
                <div class="tableTd">{{ item.prjName }}</div>
                <div class="tableTd">{{ item.title }}</div>
                <div class="tableTd">{{ item.releaseTime }}</div>
                <div class="tableTd">
                  <el-button type="text" @click="rowView(item)">查看</el-button>
                </div>
              </div>
            </div>
            <div v-else class="tableBody noData"></div>
          </div>
        </div>
      </div>
      <div class="itemBody4">
        <div class="itemBodyTitle">今日警报与通知</div>
        <div class="itemBodyContent">
          <el-carousel height="100%" interval="8000">
            <el-carousel-item v-for="(item, index) in warningNowData" :key="index"
              style="display: flex; flex-wrap: wrap; align-items: flex-start">
              <div class="iconBox" v-for="(itemIcon, index) in item" :key="index"
                :style="{ 'filter': itemIcon.cs == 0 ? 'brightness(0.5)' : 'none', }">
                <div class="iconImg" :style="{ 'background-image': `url(${itemIcon.imgurl})`, }">
                  <!-- <img :src="itemIcon.imgurl" alt="" /> -->
                </div>
                <span>{{ itemIcon.cs }}</span>
                <span>{{ itemIcon.type_name }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="itemBody5">
        <div class="itemBodyTitle">累计预警提示</div>
        <div class="itemBodyContent" id="chart"></div>
      </div>
    </div>
    <fileView ref="fileView" title="附件预览"></fileView>
    <!-- 查看预警信息 -->
    <el-dialog title="项目预警处置措施" :visible.sync="warningVisable" width="50%" size="mini">
      <el-form :model="warningForm" label-width="75px">
        <el-form-item label="预警单位">
          <el-input disabled v-model="warningForm.contract_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input disabled v-model="warningForm.prj_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="预警标题">
          <el-input disabled v-model="warningForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-input disabled v-model="warningForm.pub_time" autocomplete="off"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间">
              <el-input disabled v-model="warningForm.start_time" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间">
              <el-input disabled v-model="warningForm.end_time" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="发布状态">
          <el-input
            disabled
            v-model="warningForm.status"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="严重等级">
              <el-input disabled v-model="warningForm.severity" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="等级颜色">
              <el-input disabled v-model="warningForm.severity_color" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="预警类型">
          <el-input disabled v-model="warningForm.type_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详细描述">
          <el-input disabled type="textarea" style="color: #ccc" :rows="4" v-model="warningForm.text"
            autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 历史预警 -->
    <el-dialog title="历史预警" :visible.sync="warningMoreVisable" width="80%" size="mini">
      <el-table :data="warningMoreData" style="width: 100%" size="mini" height="55vh" border>
        <el-table-column align="center" width="150" show-overflow-tooltip prop="contractName" label="预警单位">
        </el-table-column>
        <el-table-column align="center" width="150" show-overflow-tooltip prop="prjName" label="项目名称">
        </el-table-column>
        <el-table-column align="center" min-width="150" show-overflow-tooltip prop="title" label="预警标题">
        </el-table-column>
        <el-table-column align="center" width="130" show-overflow-tooltip prop="pubTime" label="发布时间">
        </el-table-column>
        <el-table-column align="center" width="130" show-overflow-tooltip prop="startTime" label="开始时间">
        </el-table-column>
        <el-table-column align="center" width="130" show-overflow-tooltip prop="endTime" label="结束时间">
        </el-table-column>
        <el-table-column align="center" width="90" show-overflow-tooltip prop="severity" label="严重等级">
        </el-table-column>
        <el-table-column align="center" width="90" show-overflow-tooltip prop="severityColor" label="等级颜色">
        </el-table-column>
        <el-table-column align="center" width="80" show-overflow-tooltip prop="typeName" label="预警类型">
        </el-table-column>
        <el-table-column align="center" width="200" show-overflow-tooltip prop="text" label="详细描述">
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top: 10px" align="right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-dialog>
    <el-dialog title="预警处置措施" :visible.sync="showEarly" width="80%" size="mini">
      <dbtEarlyWarningDisposal v-if="showEarly" :type="warningMeasureType" :id="warningMeasureid">
      </dbtEarlyWarningDisposal>
    </el-dialog>
  </div>
</template>

<script>
import { fullscreenToggel } from "@/util/util";
import { dateFormat } from "@/util/date";
import dbtEarlyWarningDisposal from "@/views/dbtEarlyWarningDisposal/edit.vue";
// weatherInfo
import {
  weatherInfo,
  weatherInfoWarning,
  warningMeasure,
} from "../../../api/visualization/index";
export default {
  name: "weatherDisasterWarning",
  data() {
    return {
      infoData: {
        accumulatedMonitoring: 0,
        accumulatedWarning: 0,
        currentMonitoring: 0,
        currentWarning: 0,
      },
      warningNowData: [],
      warningSum: 0,
      warningMoreVisable: false,
      warningMoreData: [],
      table1Data: [],
      table2Data: [],
      table1Moving: false,
      fullScreen: false,
      table1Timer: null, // 添加一个 Timer 变量用于存储定时器
      table2Moving: false,
      table2Timer: null, // 添加一个 Timer 变量用于存储定时器
      imgObject: {
        tf: require("../../../assets/visualization/weatherDisasterWarning/tf.png"),
        ljf: require("../../../assets/visualization/weatherDisasterWarning/ljf.png"),
        by: require("../../../assets/visualization/weatherDisasterWarning/by.png"),
        bx: require("../../../assets/visualization/weatherDisasterWarning/bx.png"),
        dx: require("../../../assets/visualization/weatherDisasterWarning/dx.png"),
        hc: require("../../../assets/visualization/weatherDisasterWarning/hc.png"),
        df: require("../../../assets/visualization/weatherDisasterWarning/df.png"),
        scb: require("../../../assets/visualization/weatherDisasterWarning/scb.png"),
        dwdh: require("../../../assets/visualization/weatherDisasterWarning/dwdh.png"),
        gw: require("../../../assets/visualization/weatherDisasterWarning/gw.png"),
        ld: require("../../../assets/visualization/weatherDisasterWarning/ld.png"),
        bb: require("../../../assets/visualization/weatherDisasterWarning/bb.png"),
        bd: require("../../../assets/visualization/weatherDisasterWarning/sd.png"),
        dw: require("../../../assets/visualization/weatherDisasterWarning/dw.png"),
        m: require("../../../assets/visualization/weatherDisasterWarning/m.png"),
        lydf: require("../../../assets/visualization/weatherDisasterWarning/lydf.png"),
        dljb: require("../../../assets/visualization/weatherDisasterWarning/dljb.png"),
        dzzhfx: require("../../../assets/visualization/weatherDisasterWarning/dzzhfx.png"),
        dgxh: require("../../../assets/visualization/weatherDisasterWarning/dgxh.png"),
      },
      colorType: {
        White: "白色",
        Blue: "蓝色",
        Green: "绿色",
        Yellow: "黄色",
        Orange: "橙色",
        Red: "红色",
        Black: "黑色",
      },
      warningVisable: false,
      warningForm: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      showEarly: false,
      // 预警措施详情
      warningMeasureid: "",
      warningMeasureType: 'view'
    };
  },
  components: {
    dbtEarlyWarningDisposal,
  },
  mounted() {
    this.getEcharts();
    this.getWarningNowData();
    setInterval(() => {
      this.getEcharts();
      this.getWarningNowData();
    }, 1000 * 60 * 30);
  },

  methods: {
    fullScreenClick() {
      this.fullScreen = true;
      fullscreenToggel();
    },
    outFullScreenClick() {
      this.fullScreen = false;
      fullscreenToggel();
    },
    getWarningNowData() {
      weatherInfo({ mode: "xmxx" }).then((res) => {
        this.infoData = res.data.data;
      });
      // 预警项目
      weatherInfo({ mode: "yjxm" }).then((res) => {
        res.data.data.forEach((item, index) => {
          item.sort = index + 1;
          item.end_time == ""
            ? (item.end_time = item.end_time)
            : (item.end_time = dateFormat(
              new Date(item.end_time),
              "yyyy-MM-dd hh:mm"
            ));
          item.pub_time == ""
            ? (item.pub_time = item.pub_time)
            : (item.pub_time = dateFormat(
              new Date(item.pub_time),
              "yyyy-MM-dd hh:mm"
            ));
          item.start_time == ""
            ? (item.start_time = item.start_time)
            : (item.start_time = dateFormat(
              new Date(item.start_time),
              "yyyy-MM-dd hh:mm"
            ));
        });

        this.table1Data = res.data.data;
        this.warningSum = res.data.data.length;
        this.$nextTick(() => {
          this.openTimer("table1");
        });
      });
      // 预警处置措施
      warningMeasure().then((res) => {
        let data = res.data.data;
        data.records.forEach((item, index) => {
          item.sort = index + 1;
          item.releaseTime == ""
            ? (item.releaseTime = item.releaseTime)
            : (item.releaseTime = dateFormat(
              new Date(item.releaseTime),
              "yyyy-MM-dd hh:mm"
            ));
        });
        this.table2Data = data.records;
      });
      // 当前预警与通知
      weatherInfo({ mode: "dqjb" }).then((res) => {
        let data = res.data.data;
        data.forEach((item) => {
          item.forEach((itemIcon) => {
            itemIcon.imgurl = this.imgObject[itemIcon.icon];
          });
        });
        this.warningNowData = data;
        console.log(data);
      });
    },
    getEcharts() {
      let echarts = this.$echarts;
      let that = this;
      // 绘制左侧面
      const wid = 15;
      const w1 = Math.sin(Math.PI / 6) * wid; //4
      const w2 = Math.sin(Math.PI / 3) * wid; // 6.8
      const snapHeight = wid / 2;
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c0 = [shape.x, shape.y];
          const c1 = [shape.x - w2, shape.y];
          const c2 = [shape.x - w2, xAxisPoint[1]];
          const c3 = [shape.x, xAxisPoint[1]];
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath();
        },
      });
      // 绘制右侧面
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x, xAxisPoint[1]];
          const c3 = [shape.x + w1, xAxisPoint[1] - w2 + snapHeight];
          const c4 = [shape.x + w1, shape.y - w2 + snapHeight];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 绘制顶面
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          //
          const c1 = [shape.x, shape.y];
          const c2 = [shape.x + w1, shape.y - w2 + snapHeight]; //右点
          const c3 = [shape.x - w2 + w1, shape.y - w2 + snapHeight];
          const c4 = [shape.x - w2, shape.y];
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });
      // 三个面图形
      echarts.graphic.registerShape("CubeLeft", CubeLeft);
      echarts.graphic.registerShape("CubeRight", CubeRight);
      echarts.graphic.registerShape("CubeTop", CubeTop);
      var dom = document.getElementById("chart");
      echarts.init(dom).dispose();
      var myChart = echarts.init(dom);
      // const MAX =   [78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78, 78];
      // const VALUE = [ 1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
      let data = [];
      let MAX = [];
      let VALUE = [];
      let dataX = [];
      weatherInfo({ mode: "ljyj" }).then((res) => {
        data = res.data.data;
        var a = 1;
        data.forEach((item) => {
          dataX.push(item.type_name);
          VALUE.push(item.cs);
        });

        data.forEach((item) => {
          MAX.push(Math.max(...VALUE) + 10);
        });

        let option = null;
        option = {
          title: {
            text: "",
            top: 32,
            left: 18,
            textStyle: {
              color: "#00F6FF",
              fontSize: 24,
            },
          },
          grid: {
            left: 20,
            right: 40,
            bottom: "10%",
            top: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            // data: [],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#1662a7",
              },
            },
            axisTick: {
              show: false,
              length: 9,
              alignWithLabel: true,
              lineStyle: {
                color: "#7DFFFD",
              },
            },
            axisLabel: {
              fontSize: 10,
              color: "#fff",
              formatter: function (params) {
                var val = "";
                if (params.length > 4) {
                  val =
                    params.substr(0, 4) +
                    "\n" +
                    params.substr(4, params.length);
                  return val;
                } else {
                  return params;
                }
              },
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "white",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ffffff",
                opacity: 0.2,
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 12,
            },
            boundaryGap: ["0%", "20%"], // [20%，20%] 控制顶部和底部的空白
          },
          series: [
            {
              type: "custom",
              renderItem: function (params, api) {
                const location = api.coord([api.value(0), api.value(1)]);
                return {
                  type: "group",
                  children: [
                    {
                      type: "CubeLeft",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: "rgba(87, 192, 254,.2)",
                      },
                    },
                    {
                      type: "CubeRight",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: "rgba(88, 178, 230,.3)",
                      },
                    },
                    {
                      type: "CubeTop",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: "rgba(88, 178, 230,.4)",
                      },
                    },
                  ],
                };
              },
              // data: [],
            },
            {
              type: "custom",
              renderItem: (params, api) => {
                const location = api.coord([api.value(0), api.value(1)]);
                return {
                  type: "group",
                  children: [
                    {
                      type: "CubeLeft",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: "#5dc6fe",
                          },
                          {
                            offset: 1,
                            color: "#248ffd",
                          },
                        ]),
                      },
                    },
                    {
                      type: "CubeRight",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: "#6ac8f7",
                          },
                          {
                            offset: 1,
                            color: "#115ba2",
                          },
                        ]),
                      },
                    },
                    {
                      type: "CubeTop",
                      shape: {
                        api,
                        xValue: api.value(0),
                        yValue: api.value(1),
                        x: location[0],
                        y: location[1],
                        xAxisPoint: api.coord([api.value(0), 0]),
                      },
                      style: {
                        fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: "#3691c7",
                          },
                          {
                            offset: 1,
                            color: "#49BEE5",
                          },
                        ]),
                      },
                    },
                  ],
                };
              },
              // data: [],
            },
            {
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                  color: "#fff",
                  offset: [-3, -10],
                },
              },
              itemStyle: {
                color: "transparent",
              },
              // data: [],
            },
          ],
        };
        var Interval = null;
        clearInterval(Interval);
        InItOpen();
        Interval = setInterval(InItOpen, 3000);
        function InItOpen() {
          if (dataX.length > 7) {
            let data = that.baseData(option, VALUE, 7);
            option.xAxis.data = that.baseData(option, dataX, 7);
            option.series[0].data = that.baseData(option, MAX, 7);
            option.series[1].data = data;
            option.series[2].data = data;
          } else {
            option.xAxis.data = dataX;
            option.series[0].data = MAX;
            option.series[1].data = VALUE;
            option.series[2].data = VALUE;
          }
          myChart.setOption(option);
        }
        option && myChart.setOption(option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      });
    },
    // 动态数据
    baseData(option, name, size) {
      var NameData = [];
      var Data_Name = name;
      Data_Name.push(Data_Name[0]);
      Data_Name.splice(0, 1);
      for (var i = 0; i < Data_Name.length; i++) {
        NameData.push(Data_Name[i]);
        if (i + 1 == size) {
          break;
        }
      }
      return NameData;
    },
    openTimer(name) {
      switch (name) {
        case "table1":
          if (this.table1Data.length > 4) {
            this.moveData1("#table1", 3000, 3.5);
          }
          break;
        case "table2":
          if (this.table2Data.length > 4) {
            this.moveData2("#table2", 3000, 3.5);
          }
          break;
      }
    },
    moveData1(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        if (this.table1Moving) {
          return; // 如果正在执行，则直接返回，避免重复执行
        }
        if (this.table1Data.length < 5) {
          return; // 如果正在执行，则直接返回，避免重复执行
        }
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `calc(${-1 * goHeight}vh - 5px)`;
        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.table1Data.shift();
            this.table1Data.push(firstChild);
            clearTimeout(this.table1Timer);
            this.table1Timer = setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
    moveData2(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        if (this.table2Moving) {
          return; // 如果正在执行，则直接返回，避免重复执行
        }
        if (this.table2Data.length < 5) {
          return; // 如果正在执行，则直接返回，避免重复执行
        }
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `calc(${-1 * goHeight}vh - 5px)`;
        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.table2Data.shift();
            this.table2Data.push(firstChild);
            clearTimeout(this.table2Timer);
            this.table2Timer = setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
    // 查看预览 下载
    rowView(row) {
      // this.$refs.fileView.showFile(
      //   "https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics4.baidu.com%2Ffeed%2F72f082025aafa40ff6e8ae42bb03cc4179f019fc.jpeg%40f_auto%3Ftoken%3Deb6d0dcab736dd98e08e3cade451ea96&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1729616400&t=b65e45af205fd91e6838a4ced73964f3"
      // );
      this.warningMeasureid = row.id;
      this.showEarly = true;
    },
    viewDetails(row) {
      this.warningVisable = true;
      console.log(row);
      switch (row.severity_color) {
        case 'White':
          row.severity_color = '白色'
          break;
        case 'Blue':
          row.severity_color = '蓝色'
          break;
        case 'Green':
          row.severity_color = '绿色'
          break;
        case 'Yellow':
          row.severity_color = '黄色'
          break;
        case 'Orange':
          row.severity_color = '橙色'
          break;
        case 'Red':
          row.severity_color = '红色'
          break;
        case 'Black':
          row.severity_color = '黑色'
          break;
      }
      this.warningForm = row;
    },
    // 更多预警，历史预警
    warningMore() {
      let params = {
        size: this.pageSize,
        current: this.currentPage,
      };
      weatherInfoWarning(params).then((res) => {
        let data = res.data.data;
        data.records.forEach((item) => {
          switch (item.severityColor) {
            case 'White':
              item.severityColor = '白色'
              break;
            case 'Blue':
              item.severityColor = '蓝色'
              break;
            case 'Green':
              item.severityColor = '绿色'
              break;
            case 'Yellow':
              item.severityColor = '黄色'
              break;
            case 'Orange':
              item.severityColor = '橙色'
              break;
            case 'Red':
              item.severityColor = '红色'
              break;
            case 'Black':
              item.severityColor = '黑色'
              break;
          }
          item.end_time == ""
            ? (item.end_time = item.end_time)
            : (item.end_time = dateFormat(
              new Date(item.end_time),
              "yyyy-MM-dd hh:mm"
            ));
          item.pub_time == ""
            ? (item.pub_time = item.pub_time)
            : (item.pub_time = dateFormat(
              new Date(item.pub_time),
              "yyyy-MM-dd hh:mm"
            ));
          item.start_time == ""
            ? (item.start_time = item.start_time)
            : (item.start_time = dateFormat(
              new Date(item.start_time),
              "yyyy-MM-dd hh:mm"
            ));
        });
        this.warningMoreData = data.records;
        this.total = data.total;
      });
      this.warningMoreVisable = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.warningMore();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.warningMore();
    },
    warningMeasure() {
      window.open(
        location.origin + "/dbtEarlyWarningDisposal/index?type=largeScreen"
      );
      // this.$router.push({
      //   path: `/dbtEarlyWarningDisposal/index?type=largeScreen`,
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.weatherDisasterWarning * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.weatherDisasterWarning .el-form-item {
  margin-bottom: 10px;
}

.weatherDisasterWarning ::v-deep .el-textarea__inner {
  color: #606266 !important;
}

.weatherDisasterWarning {
  background: url("../../../assets/visualization/weatherDisasterWarning/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ::v-deep .el-badge__content {
    border: 0;
  }

  .weatherHeader {
    width: 100%;
    height: 90px;
    background-image: url("../../../assets/visualization/weatherDisasterWarning/weatherHeader.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.563vw;

    img {
      height: 50%;
    }

    .fullScreen {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: #fff;
    }
  }

  .weatherBody {
    width: 100%;
    height: calc(100% - 90px);
    padding: 1.563vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    color: #fff;

    .warningProject {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .warningProject {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .buttonBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    >div {
      margin-bottom: 20px;
      width: calc(50% - 10px);
      padding: 1.042vw;
      padding-bottom: 0;

      .itemBodyTitle {
        color: #fefefe;
        padding-left: 3.906vw;
        line-height: 2.344vw;
        font-size: 0.833vw;
        width: 100%;
        height: 2.344vw;
        background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBodyTitle.png");
        background-repeat: no-repeat;
        background-size: auto 100%;
      }

      .itemBodyContent {
        width: 100%;
        height: calc(100% - 2.344vw);
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .iconBox {
          width: 25%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;

          .iconImg {
            width: 50%;
            background-repeat: no-repeat;
            background-size: 85% auto;
            height: 100%;
            background-position: center;
          }

          span {
            width: 50%;
            height: 30%;
          }

          >span:first-child {
            font-size: 1.146vw;
          }

          >span:last-child {
            font-size: 0.833vw;
          }
        }
      }

      .itemBodyContent>div {
        width: 100%;
        height: 100%;
      }
    }

    .itemBody1 {
      flex: 1;
      margin-right: 20px;
      background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBody1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .iconBox {
        height: 100%;
      }

      .iconBox:nth-child(1) .iconImg {
        background-image: url("../../../assets/visualization/weatherDisasterWarning/letTop1.png");
        background-size: 60% auto;
      }

      .iconBox:nth-child(2) .iconImg {
        background-image: url("../../../assets/visualization/weatherDisasterWarning/leftTop2.png");
        background-size: 60% auto;
      }

      .iconBox:nth-child(3) .iconImg {
        background-image: url("../../../assets/visualization/weatherDisasterWarning/leftTop3.png");
        background-size: 60% auto;
      }

      .iconBox:nth-child(4) .iconImg {
        background-image: url("../../../assets/visualization/weatherDisasterWarning/leftTop4.png");
        background-size: 60% auto;
      }
    }

    .itemBody2 {
      height: 35%;
      margin-right: 20px;
      background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBody2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .itemBody3 {
      height: 35%;
      margin-bottom: 0px;
      margin-right: 20px;
      background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBody2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .itemBody4 {
      height: calc(50% - 10px);
      background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBody45.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .iconBox {
        height: 33.333%;
      }
    }

    .itemBody5 {
      height: calc(50% - 10px);
      margin-bottom: 0px;
      background-image: url("../../../assets/visualization/weatherDisasterWarning/itemBody45.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}

.noData {
  background-image: url("../../../assets/visualization/noData.png");
  background-repeat: no-repeat;
  background-size: auto 55%;
  background-position: center;
}

.tableBox {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .tableHeader {
    color: #00deff;
    height: 1.823vw;
  }

  .tableBody {
    width: 100%;
    height: calc(100% - 1.823vw - 15px);
  }

  .tableTh {
    width: 100%;
    margin-top: 5px;
    height: 3.5vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 0.729vw;

    .tableTd {
      height: 100%;
      line-height: 3.5vh;
      text-align: center;
      font-size: 0.833vw;
      white-space: nowrap;
      /* 禁止换行 */
      overflow: hidden;
      /* 超出部分隐藏 */
      text-overflow: ellipsis;
    }
  }
}

.table1 .tableHeader {
  background-image: url("../../../assets/visualization/weatherDisasterWarning/leftCenterTitle.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.table1 .tableBody>.tableTh {
  background-image: url("../../../assets/visualization/weatherDisasterWarning/leftCenterItem.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.table1 .tableTh>div:nth-child(1) {
  width: 5%;
}

.table1 .tableTh>div:nth-child(2) {
  width: 20%;
}

.table1 .tableTh>div:nth-child(3) {
  width: 25%;
}

.table1 .tableTh>div:nth-child(4) {
  width: 15%;
}

.table1 .tableTh>div:nth-child(5) {
  width: 20%;
}

.table1 .tableTh>div:nth-child(6) {
  width: 10%;
}

.table2 .tableBody>.tableTh {
  background-image: url("../../../assets/visualization/weatherDisasterWarning/leftBottomItem.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.table2 .tableTh>div:nth-child(1) {
  width: 5%;
}

.table2 .tableTh>div:nth-child(2) {
  width: 20%;
}

.table2 .tableTh>div:nth-child(3) {
  width: 30%;
}

.table2 .tableTh>div:nth-child(4) {
  width: 20%;
}

.table2 .tableTh>div:nth-child(5) {
  width: 20%;
}

.table2 .tableTh>div:nth-child(6) {
  width: 5%;
}
</style>
